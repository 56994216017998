import { graphql, SliceComponentProps, useStaticQuery } from 'gatsby';
import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { createMarkup, getBackgroundColor } from '../../utils';
import { Button } from '../button';
import { Container } from '../container';
import {
  Facebook,
  Twitter,
  Youtube,
  Instagram,
  LinkedIn,
  Logo2,
} from '../icon';
import { LinkLabel } from '../linkLabel';
import { LinkTo } from '../linkTo/LinkTo';
import { Spacer } from '../spacer';
import { Typography } from '../typography';

// Interface

export interface FooterProps {
  propName?: string;
}

export interface FooterMenuProps {
  header?: string;
  links?: any[];
}

// Styles

const StyledFooter = styled.footer`
  ${({ theme: { space, media, colors } }) => css`
    ${getBackgroundColor('neutralGrey')};
    padding: ${space.large} 0;
    position: relative;
    color: ${colors.neutralWhite};
    overflow: hidden;

    @media (min-width: ${media.medium}) {
      padding: ${space.xLarge} 0;
    }
  `};
`;

const StyledLogo2 = styled(Logo2)`
  ${() => css`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 456px;
    height: 456px;
    pointer-events: none;
  `};
`;

const StyledUpperWrapper = styled(Spacer)`
  ${({ theme: { space, media } }) => css`
    margin-bottom: ${space.large};

    @media (min-width: ${media.xLarge}) {
      margin-bottom: ${space.xLarge};
    }
  `};
`;

const StyledLogos = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.medium};

    @media (min-width: ${media.medium}) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  `};
`;

const StyledSocialLinks = styled.ul`
  ${({ theme: { space, colors } }) => css`
    display: flex;
    gap: ${space.xxSmall};

    li a {
      color: ${colors.neutralGrey};
      background-color: ${colors.neutralWhite};
    }
  `};
`;

const StyledFootnote = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.medium};

    @media (min-width: ${media.large}) {
      justify-content: space-between;
    }
  `};
`;

const StyledSmallLinks = styled.ul`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xSmall};

    @media (min-width: ${media.large}) {
      flex-direction: row;
      gap: ${space.xSmall};
    }
  `};
`;

const StyledMenus = styled.div`
  ${({ theme: { space, media } }) => css`
    @media (min-width: ${media.large}) {
      display: flex;
      justify-content: space-between;
      gap: ${space.medium};
    }
  `};
`;

const StyledMenuSpacer = styled(Spacer)`
  ${({ theme: { space } }) => css`
    margin-bottom: ${space.large};
  `};
`;

const StyledInline = styled.div`
  ${({ theme: { space, media } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space.xSmall};

    > :nth-of-type(1) {
      order: 2;
    }
    > :nth-of-type(2) {
      order: 1;
    }

    @media (min-width: ${media.large}) {
      flex-direction: row;

      > :nth-of-type(1) {
        order: 1;
      }
      > :nth-of-type(2) {
        order: 2;
      }
    }
  `};
`;

// JSX

export const FooterMenu = ({ header, links }: FooterMenuProps) => (
  <Spacer spacing="small">
    <Typography component="h4" variant="headingMediumSmall">
      {header}
    </Typography>
    <StyledMenuSpacer as="ul" spacing="xSmall">
      {links?.map(({ linkName, url }) => (
        <li key={linkName}>
          <LinkLabel label={linkName} url={url} inverse underline />
        </li>
      ))}
    </StyledMenuSpacer>
  </Spacer>
);

export const Footer = () => {
  const { footer, socialLinks } = useStaticQuery(graphql`
    query FooterQuery {
      socialLinks: umbracoSocialLinksType {
        facebook
        twitter
        linkedIn
        instagram
        pinterest
        youTube
      }
      footer: umbracoFooterType {
        smallText
        brandMessage
        navigationColumns {
          block {
            components {
              __typename
              ... on UMBRACO_FooterMenuComponentType {
                header
                links {
                  linkName
                  target
                  url
                }
              }
            }
          }
        }
        secondaryNavigation {
          linkName
          target
          url
        }
      }
    }
  `);

  return (
    <StyledFooter>
      <Container>
        <StyledUpperWrapper>
          <StyledMenus>
            {footer?.navigationColumns?.[0]?.block?.components &&
              footer.navigationColumns[0]?.block.components.length >= 1 &&
              footer?.navigationColumns[0].block.components.map(
                (nav, index) => (
                  <FooterMenu
                    header={nav?.header}
                    links={nav?.links}
                    key={index}
                  />
                )
              )}
          </StyledMenus>
        </StyledUpperWrapper>
        <Spacer>
          <StyledLogos>
            <StyledSocialLinks>
              {socialLinks?.facebook && (
                <li>
                  <Button
                    url={socialLinks.facebook}
                    a11yTitle="Facebook"
                    small
                    target="_blank"
                    rel="noreferrer noopener"
                    iconRight={<Facebook />}
                  />
                </li>
              )}
              {socialLinks?.twitter && (
                <li>
                  <Button
                    url={socialLinks.twitter}
                    a11yTitle="Twitter"
                    small
                    target="_blank"
                    rel="noreferrer noopener"
                    iconRight={<Twitter />}
                  />
                </li>
              )}
              {socialLinks?.linkedIn && (
                <li>
                  <Button
                    url={socialLinks.linkedIn}
                    a11yTitle="LinkedIn"
                    small
                    target="_blank"
                    rel="noreferrer noopener"
                    iconRight={<LinkedIn />}
                  />
                </li>
              )}
              {socialLinks?.instagram && (
                <li>
                  <Button
                    url={socialLinks?.instagram}
                    a11yTitle="Instagram"
                    small
                    target="_blank"
                    rel="noreferrer noopener"
                    iconRight={<Instagram />}
                  />
                </li>
              )}
              {socialLinks?.youTube && (
                <li>
                  <Button
                    //
                    url={socialLinks.youTube}
                    a11yTitle="Youtube"
                    small
                    target="_blank"
                    rel="noreferrer noopener"
                    iconRight={<Youtube />}
                  />
                </li>
              )}
            </StyledSocialLinks>
          </StyledLogos>

          <StyledFootnote>
            {footer?.smallText && (
              <Typography
                variant="bodySmall"
                color="neutralWhite"
                component="div"
              >
                {createMarkup(footer.smallText)}
              </Typography>
            )}

            <StyledInline>
              <Typography
                variant="bodySmall"
                color="neutralWhite"
                component="div"
              >
                © Trade Direct Insurance Services Ltd; All Rights Reserved.
              </Typography>

              {footer?.secondaryNavigation?.length && (
                <StyledSmallLinks>
                  {footer?.secondaryNavigation?.map((nav, index) => (
                    <Typography
                      component="li"
                      variant="bodySmall"
                      color="neutralWhite"
                      key={index}
                    >
            
                      {nav?.url && (
                        <LinkTo to={nav.url}>
                          {nav?.linkName}
                        </LinkTo>
                      )}
                    </Typography>
                  ))}
                </StyledSmallLinks>
              )}
            </StyledInline>
          </StyledFootnote>
        </Spacer>
      </Container>
      <StyledLogo2 />
    </StyledFooter>
  );
};

export default Footer;
